import React, { useMemo } from 'react'
import classNames from 'classnames'
import { StaticImage } from 'gatsby-plugin-image'

import {
  Paragraph1,
  Paragraph2,
  SpecialHeader2,
} from '../components/shared/Text'
import LocaleSwitch from '../components/shared/LocaleSwitch'

import { InnerContainer, OuterContainer } from '../components/Containers'
import LocaleContext from '../contexts/localContext'
import { messages } from '../hooks/useMessages'
import Seo from '../components/seo'

const MobileLogo = ({ className }) => (
  <StaticImage
    className={`"lg:hidden lg:pt-12" w-80 pb-6 pt-2 lg:w-96 2xl:w-[36rem] ${className}`}
    src="../../static/pics/ComingSoon/STR_logo_no_bkgrnd.png"
    alt="Star Trek Resurgence home page"
    layout="fullWidth"
    objectFit="cover"
  />
)

function SpecialLayout({ pageContext: { locale }, bgClassName, children }) {
  const value = useMemo(() => ({ locale }), [locale])
  return (
    <LocaleContext.Provider value={value}>
      <div
        className="
        min-h-screen min-w-[360px] flex-col
        justify-between
        bg-background
        text-white
      "
      >
        <div className="relative w-full">
          <div className="absolute z-0">
            <StaticImage
              className={classNames(
                'w-full object-cover opacity-60',
                bgClassName
              )}
              alt="coming soon"
              src="../../static/pics/Common/xl_large_error.png"
              layout="constrained"
              objectFit="cover"
            />
          </div>
          <div className="g-gradient-to-t absolute right-0 -z-10 from-background to-transparent">
            <div className="absolute top-0 right-0 h-full w-full bg-gradient-to-r from-background to-transparent" />
            <div className="absolute bottom-0 right-0 h-full w-full bg-gradient-to-t from-background to-transparent" />
          </div>
        </div>

        <div className="absolute z-10 mx-auto w-full">
          <div className="z-10 hidden h-navbar flex-row justify-end px-20 lg:flex">
            <LocaleSwitch className="self-center" />
          </div>
          <div className="min-h-contentMinH z-10 flex flex-col justify-between lg:justify-center">
            {children}

            <div className="h-localeFooter flex w-full items-center justify-center lg:hidden">
              <LocaleSwitch />
            </div>
          </div>
        </div>
      </div>
    </LocaleContext.Provider>
  )
}

export default function NotFound({ pageContext }) {
  const { locale } = pageContext
  const msg = messages(locale)
  const seo = {
    metaTitle: msg('not-found'),
    metaDescription: msg('not-found-desc'),
  }
  return (
    <SpecialLayout pageContext={pageContext} bgClassName="!opacity-100">
      <Seo seo={seo} />
      <OuterContainer>
        <MobileLogo className="lg:block" />
        <SpecialHeader2 className="pb-6 uppercase text-primary">
          {msg('not-found')}
        </SpecialHeader2>
        <InnerContainer>
          <Paragraph1 className="pb-2">{msg('not-found-desc')}</Paragraph1>
          <Paragraph2>
            {msg('not-found-go-back')}{' '}
            <a
              className="text-primary visited:text-visited"
              href="http://www.startrek-resurgence.com"
            >
              Star Trek Resurgence
            </a>
          </Paragraph2>
        </InnerContainer>
      </OuterContainer>
    </SpecialLayout>
  )
}
