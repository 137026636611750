import React, { useCallback } from 'react'

import LocaleContext from '../contexts/localContext'

const dictionary = {
  en: {
    'watch-trailer': 'Watch trailer',
    'buy-now': 'Buy Now',
    'sto-promo-offer': 'Star Trek online offer',

    error: 'There was an error.',
    invalidEmail: 'That does not look like an email address.',
    back: 'BACK',
    press: 'Press',
    privacy: 'Privacy Policy',
    download: 'Download',
    video: 'Video',
    herocta: 'BUY NOW',
    herotitle: 'Star&nbsp;Trek™:&nbsp;Resurgence',
    wishlist: 'Wishlist',

    'not-found': 'ERROR 404',
    'not-found-desc':
      'We regret to inform you that the page you have requested does not exist in this sector of the galaxy. Our sensors have scanned the entire database, and the information you seek is not within our records.',
    'not-found-go-back': 'Go back to ',
    'store.title': 'Get the game',
    'store.desc':
      'Star Trek: Resurgence is available to download or you can buy the physical disc from your favorite retailer.',
    'store.chooseRegion': 'Choose Region...',
    'store.modal.desc': 'Find your game at these stores',
    'store.Physical': 'Buy Physical',
    'store.Digital': 'Buy Digital',
    'media.title': 'Media',
    'featured-media.title': 'Featured content',
    'sitemap.forum': 'Forum',
    'sitemap.support': 'Support',

    'accolades.title': 'What the press says',
  },
  es: {
    'watch-trailer': 'Ver trailer',
    'buy-now': 'Comprar Ahora',
    'sto-promo-offer': 'Oferta de Star Trek online',

    error: 'Ocurrió un error.',
    invalidEmail: 'Eso no parece ser una dirección de email.',
    back: 'VOLVER',
    press: 'Prensa',
    privacy: 'Política de Privacidad',
    download: 'Descargar',
    video: 'Video',
    herocta: 'COMPRA YA',
    herotitle: 'Star&nbsp;Trek™:&nbsp;Resurgence',
    wishlist: 'Lista de deseos',

    'not-found': 'ERROR 404',
    'not-found-desc':
      'Lamentamos informarle que la página que ha solicitado no existe en este sector de la galaxia. Nuestros sensores han escaneado toda la base de datos y la información que busca no está en nuestros registros.',
    'not-found-go-back': 'Volver al sitio de ',
    'store.title': 'Consigue el juego',
    'store.desc':
      'Star Trek: Resurgence está disponible para descargar o puedes comprar el disco físico en tu tienda favorita.',
    'store.chooseRegion': 'Elige una región...',
    'store.modal.desc': 'Encuentra tu juego en estas tiendas',
    'store.Physical': 'Comprar Físico',
    'store.Digital': 'Comprar Digital',
    'media.title': 'Medios',
    'featured-media.title': 'Contenido destacado',
    'sitemap.forum': 'Foro',
    'sitemap.support': 'Soporte',

    'accolades.title': 'Lo que dice la prensa',
  },
  fr: {
    'watch-trailer': 'Bande-annonce',
    'buy-now': 'Acheter maintenant',
    'sto-promo-offer': 'Offre Star Trek en ligne',

    error: 'Il y avait une erreur.',
    invalidEmail: 'Cela ne semble pas être une adresse e-mail.',
    back: 'REVENIR',
    press: 'Presse',
    privacy: 'Politique de Confidentialité',
    download: 'Télécharger',
    video: 'Vidéo',
    herocta: 'ACHETER',
    herotitle: 'Star&nbsp;Trek™:&nbsp;Resurgence',
    wishlist: 'Liste de souhaits',

    'not-found': 'ERREUR 404',
    'not-found-desc': `Nous avons le regret de vous informer que la page que vous avez demandée n'existe pas dans ce secteur de la galaxie. Nos capteurs ont scanné l'intégralité de la base de données et les informations que vous recherchez ne figurent pas dans nos archives.`,
    'not-found-go-back': 'Retour au site ',
    'store.title': 'Obtenir le jeu',
    'store.desc':
      'Star Trek : Resurgence est disponible en téléchargement ou vous pouvez acheter le disque physique auprès de votre détaillant préféré.',
    'store.chooseRegion': 'Choisissez une région...',
    'store.modal.desc': 'Trouvez votre jeu dans ces magasins',
    'store.Physical': 'Acheter Physique',
    'store.Digital': 'Acheter Digital',
    'media.title': 'Média',
    'featured-media.title': 'Contenu en vedette',
    'sitemap.forum': 'Forum',
    'sitemap.support': 'Support',

    'accolades.title': 'Les avis de la presse',
  },
  de: {
    'watch-trailer': 'Trailer ansehen',
    'buy-now': 'Jetzt kaufen',
    'sto-promo-offer': 'Star Trek Online-Angebot',

    error: 'Es ist ein Fehler aufgetreten.',
    invalidEmail: 'Das sieht nicht wie eine E-Mail-Adresse aus.',
    back: 'zurück',
    press: 'Drücken',
    privacy: 'Datenschutzerklärung',
    download: 'Herunterladen',
    Video: 'Video',
    herocta: 'JETZT KAUFEN',
    herotitle: 'Star&nbsp;Trek™:&nbsp;Resurgence',
    wishlist: 'Wunschzettel',
    'not-found': 'FEHLER 404',
    'not-found-desc':
      'Wir bedauern, Ihnen mitteilen zu müssen, dass die von Ihnen angeforderte Seite in diesem Sektor der Galaxie nicht existiert. Unsere Sensoren haben die gesamte Datenbank gescannt und die von Ihnen gesuchten Informationen befinden sich nicht in unseren Aufzeichnungen.',
    'not-found-go-back': 'Zurück zu ',
    'store.title': 'Hol dir das Spiel',
    'store.desc':
      'Star Trek: Resurgence kann heruntergeladen werden oder Sie können die physische CD bei Ihrem bevorzugten Händler kaufen.',
    'store.chooseRegion': 'Region auswählen...',
    'store.modal.desc': 'Finden Sie Ihr Spiel in diesen Geschäften',
    'store.Physical': 'Kaufen Physisch',
    'store.Digital': 'Kaufen Digital',
    'media.title': 'Medien',
    'featured-media.title': 'Empfohlener Inhalt',
    'sitemap.forum': 'Forum',
    'sitemap.support': 'Unterstützung',

    'accolades.title': 'PRESSEECHO',
  },
}
export const messages = locale => name => dictionary[locale]?.[name]

const useMessages = () => {
  const { locale } = React.useContext(LocaleContext)
  const messagesFn = useCallback(messages(locale), [locale])
  return messagesFn
}

export default useMessages
